import revive_payload_client_YTGlmQWWT5 from "/root/project/frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_neSs9z3UJp from "/root/project/frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/root/project/frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_VTarsGAzgb from "/root/project/frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_PKIXU5KmsI from "/root/project/frontend/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_LIYcCMJD18 from "/root/project/frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_SeG0EjL5Ec from "/root/project/frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_Ib6KnAhqrq from "/root/project/frontend/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/root/project/frontend/apps/b2c/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/root/project/frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_2yIKADAg6h from "/root/project/frontend/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import plugin_i8qBCPvpfd from "/root/project/frontend/node_modules/nuxt3-vuex-module/src/plugin.js";
import switch_locale_path_ssr_lCxu0ZmxIx from "/root/project/frontend/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_5C7QFp7tsq from "/root/project/frontend/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import primevue_plugin_egKpok8Auk from "/root/project/frontend/apps/b2c/.nuxt/primevue-plugin.mjs";
import plugin_client_du5xVgaLWQ from "/root/project/frontend/node_modules/nuxt-primevue/dist/runtime/plugin.client.mjs";
import broadcast_channel_WUg1bHbhSQ from "/root/project/frontend/apps/b2c/plugins/broadcast-channel.js";
import firebase_client_zXNDa0wxFH from "/root/project/frontend/apps/b2c/plugins/firebase.client.ts";
import globals_W2yFmejOiw from "/root/project/frontend/apps/b2c/plugins/globals.js";
import sentry_PZVGm6LH3G from "/root/project/frontend/apps/b2c/plugins/sentry.js";
import useFetchHandler_cWlMYuVTwe from "/root/project/frontend/apps/b2c/plugins/useFetchHandler.js";
export default [
  revive_payload_client_YTGlmQWWT5,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  payload_client_VTarsGAzgb,
  navigation_repaint_client_PKIXU5KmsI,
  check_outdated_build_client_LIYcCMJD18,
  chunk_reload_client_SeG0EjL5Ec,
  plugin_vue3_Ib6KnAhqrq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  plugin_2yIKADAg6h,
  plugin_i8qBCPvpfd,
  switch_locale_path_ssr_lCxu0ZmxIx,
  i18n_5C7QFp7tsq,
  primevue_plugin_egKpok8Auk,
  plugin_client_du5xVgaLWQ,
  broadcast_channel_WUg1bHbhSQ,
  firebase_client_zXNDa0wxFH,
  globals_W2yFmejOiw,
  sentry_PZVGm6LH3G,
  useFetchHandler_cWlMYuVTwe
]